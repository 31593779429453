<template>
  <div class="agreement-editor">
    <div class="ee-w">
      <div class="e-l">
        <div class="btn-w">
          <a-button @click.stop="closeE"> <a-icon type="left" />返回</a-button>
        </div>
      </div>
      

      <div class="e-c">
        <div class="e-w">
          <div id="myEdit"></div>
          <div class="str-l">
            <span>当前已输入2568个字符, 您还可以输入77432个字符</span>
          </div>
        </div>
      </div>

      <div class="e-r">
        <div class="s-n">
          <div class="t">
            设置模板名称
          </div>
          <div>
            <a-input placeholder="输入模板名称" />
          </div>
        </div>

        <div class="s-v">
          <div class="t">
            设置模板变量
          </div>
          <div class="ds">
            快速添加员工姓名、部门、岗位等信息，内容中会自动替换成真实信息
          </div>
          <div class="v-list">
            <div class="title">企业信息</div>
            <ul>
              <li><span @click="insertHtml('企业全称')">企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
            </ul>
          </div>

          <div class="v-list">
            <div class="title">企业信息</div>
            <ul>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
            </ul>
          </div>

          <div class="v-list">
            <div class="title">企业信息</div>
            <ul>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>
              <li><span>企业全称</span></li>

            </ul>
          </div>
        </div>
      </div>

      <div class="btn-wrapper">
        <div class="wrapper">
          <a-button type="primary" @click="getHtmlContent">保存</a-button>
        </div>
      </div>
    </div>

    

  </div>
</template>


<script>
// import { AppComponentBase } from "@/shared/component-base";
// import E from 'wangeditor'
import E from 'editor4kg'
const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E

export default {
  // mixins: [AppComponentBase],
  name: 'agreement-editor',
  props: [

  ],
  data() {
    return {
      editor: null,
      editorData: '',
      htmlData: '',
    }
  },
  computed: {
  },
  created() {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
  },
  mounted() {
    const editor = new E(`#myEdit`)

    editor.config.height = 550

    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'justify',
      'quote',
      'emoticon',
      'image',
      'video',
      'table',
      'code',
      'splitLine',
      'undo',
      'redo',
      'selectValue',
    ]

    editor.create()
    this.editor = editor
  },
  methods: {
    closeE() {
      this.$emit('closeEditor')
    },
    getHtmlContent() {
      console.log(this.editor.txt.html());
      // this.editor.txt.html()
    },
    insertHtml(item) {
      let code = 'company_name'
      let str = item
      this.editor.cmd.do('insertHTML', `
        <u class="atwho-inserted"><span class="${code}">#${str}#</span></u><span>&nbsp;</span>
      `)
    },
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].style.overflow = ''
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  },
  components: {
  }
};
</script>

<style lang="less" scoped>
@import "../../../../styles/common.less";

.agreement-editor {
  position: relative;
  .ee-w{
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    bottom: 68px;
    background: @color-white;
    overflow: auto;
    #flex;
    width: 100vw;
    // #w100;
    // height: 100vh;
    // &::before{
    //   display: table;
    //   content: "";
    // }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .e-l{
    min-width: 120px;
    position: relative;
    .btn-w{
      position: absolute;
      left: 50%;
      top: 24px;
      transform: translate3d(-50%, 0, 0);
      padding: 2px 7px 2px 6px;
    }
  }
  .e-c{
    #flex1;
    overflow: auto;
    .e-w{
      padding: 24px 30px;
      min-width: 796px;
      margin: 0 auto;
      .str-l{
        border-top: 1px solid #e1e6eb;
        font-size: 12px;
        color: #80848f;
        padding: 0 5px;
        height: 24px;
        line-height: 22px;
        text-align: right;
        background-color: #fff;
        border-radius: 0 0 4px 4px;
        bottom: 0 !important;
        top: auto !important;
        z-index: 1000 !important;
      }
    }
  }
  .e-r{
    width: 320px;
    border-left: 1px solid #f0f4f8;
    padding: 24px;
    overflow-y: auto;
    .s-n{
      .t{
        #fs18;
        margin-bottom: 16px;
        #t-color-border(@color-blue);
      }
    }
    .s-v{
      margin-top: 24px;
      .t{
        #fs18;
        margin-bottom: 12px;
        padding-bottom: 16px;
        #t-color-border(@color-blue);
        border-bottom: 1px solid #f0f4f8;
      }
      .ds{
        margin-bottom: 12px;
        font-size: 12px;
        color: #80848f;
      }
      .v-list{
        .title{
          color: @color-black;
          padding: 8px 0;
          #fs16;
          #lh20;
        }
        ul{
          #flex;
          #flex-hw;
          #flex-jcsb;
          padding-left: 0;
          margin-bottom: 0;
          li{
            width: 48%;
            margin-right: 1%;
            margin-bottom: 4%;
            span{
              #tac;
              #dib;
              padding: 3px 5px;
              #w100;
              #h100;
              border: 1px solid @color-blue;
              #border-radius(3px);
              background-color: @color-white;
              color: @color-blue;
              &:hover{
                background-color: @color-blue;
                color: @color-white;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .btn-wrapper{
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0;
    z-index: 20;
    background-color: #ecf5ff;
    .wrapper{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .atwho-inserted{
    color: @color-blue;
    border-bottom: 1px solid #000;
    padding: 0 8px;
  }
}

div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
}
div::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 72%);
  // border-radius: 3px;
}
div::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-thumb:active {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-track {
  box-shadow: 0 0 0 gray inset;
}
 
</style>