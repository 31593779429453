<template>
  <div class="templete">
    <ul>

      <li>
        <div class="title">新建/编辑模板</div>
        <div class="desc">你可以在此维护您的合同模板，发起签署时选择合同模板发起</div>

        <div class="create-edit">
          <div class="seal-list">
            <div class="item">
              <div class="img-wrapper">
                <img src="./1RAHH-DF.png" />
              </div>
              <!-- <div class="status">
                申请中
              </div> -->
              <div class="title">固定期限劳动合同</div>
              <div class="btn-wrapper">
                <div class="w">
                  <a-button type="link" @click="showEditor">编辑</a-button>
                </div>
                <div class="w">
                  <a-button type="link" @click="deleteAgreement">删除</a-button>
                </div>
              </div>
            </div>

            <div class="upload">
              <div class="wrapper">
                <div class="icon-wrapper">
                  <a-icon type="plus" />
                </div>
                <div class="title">添加印章</div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <div class="title">页眉设置</div>
        <div class="desc">可设置显示在合同模板上的页眉内容，生成PDF文件时会自动显示</div>

        <div class="page-head">
          <div class="ph-item">
            <a-form-model :model="form" labelAlign="left">
              <a-form-model-item label="企业Logo" :label-col="{span: 2}" :wrapper-col="{span: 10}" >
                <div class="i-1">
                  <div><a-button>上传logo</a-button></div>
                  <p class="tips">支持jpg、png格式，文件大小≤500KB</p>
                </div>
              </a-form-model-item>


              <a-form-model-item label="企业名称" :label-col="{span: 2}" :wrapper-col="{span: 8}">
                <a-input v-model="form.name" placeholder="请输入" />
              </a-form-model-item>

              <a-form-model-item label="样式" :label-col="{span: 2}" :wrapper-col="{span: 22}">
                <div class="i-3">
                  <a-radio-group default-value="1">
                    <a-radio-button :style="radioStyle" value="1" checked>
                      <div class="w">
                        <img src="./t1.png"  />
                      </div>
                    </a-radio-button>
                    <a-radio-button :style="radioStyle" value="2" >
                      <div class="w">
                        <img src="./t2.png" />
                      </div>
                    </a-radio-button>
                    <a-radio-button :style="radioStyle" value="3" >
                      <div class="w">
                        <img src="./t3.png" />
                      </div>
                    </a-radio-button>
                  </a-radio-group>
                </div>

              </a-form-model-item>

              <a-form-model-item :wrapper-col="{ span: 8, offset: 2 }">
                <a-button type="primary" >
                  保存
                </a-button>
              </a-form-model-item>

            </a-form-model>
          </div>
        </div>
      </li>

      <li>
        <div class="title">模板变量</div>
        <div class="desc">已启用以下变量，如需启用更多可自行添加，添加后应用于该类型下的所有合同模板</div>

        <div class="variable">
          <ul>
            <li>
              <div class="l">企业信息</div>
              <div class="r">
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
              </div>
            </li>
            <li>
              <div class="l">员工个人信息</div>
              <div class="r">
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
              </div>
            </li>
            <li>
              <div class="l">合同相关信息</div>
              <div class="r">
                <span>部门负责人<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>部门负责人<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>部门负责人<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>部门负责人<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>部门负责人<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
                <span>部门负责人<span class="split"></span></span>
                <span>企业全称<span class="split"></span></span>
              </div>
            </li>
          </ul>

          <a-button>添加更多</a-button>
        </div>
      </li>
    </ul>

    <econtract-editor @closeEditor="closeEditor" v-if="editorShow"></econtract-editor>

  </div>

</template>

<script>
import EcontractEditor from './econtract-editor.vue'

export default {
    name: "templete",
    data() {
      return {
        radioStyle: {
          padding: '10px',
          marginRight: '20px',
          marginBottom: '20px',
          width: '226px',
          height: '156px',
          lineHeight: '30px',
        },
        form: {
          name: '',
          region: undefined,
          date1: undefined,
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
        editorShow: false,
      }
    },
    created() {

    },
    mounted() {
  
    },
    methods: {
      showEditor() {
        this.editorShow = true
      },
      closeEditor() {
        this.editorShow = false
      },
      deleteAgreement() {
        this.$confirm({
          title: 'Do you Want to delete these items?',
          content: h => <div style="color:red;">Some descriptions</div>,
          onOk() {
            console.log('OK')
          },
          onCancel() {
            console.log('Cancel')
          },
          class: 'test'
        })
      }
    },
    components: {
      EcontractEditor
    }
}
</script>

<style lang="less" scope>
@import "./templete.less";

</style>
